<template>
<div>
  <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :title="`Detail ${$i18n.t('ProformaInvoice')}`" class="mt-base">
        <!-- ACTION SLOT -->
        <template slot="actions">
          <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'proforma-invoice' })">
            {{ $i18n.t('Back') }}
          </vs-button>
        </template>

        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2" v-for="(cell, i) in cells" :key="i">
            <div class="mt-5">
              <h6 class="font-bold">{{ cell.header }}</h6>
              <p>
                  <span v-if="cell.format">
                    {{ cell.format(item) }}
                  </span>
                <span v-else>
                    {{ item[cell.field] }}
                  </span>
              </p>
            </div>
          </div>
        </div>


      </vx-card>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProformaInvoiceDetail",
  data: () => ({
    currencies: [
      {
        text: 'USD',
        value: '1'
      },
      {
        text: 'EUR',
        value: '2'
      },
      {
        text: 'IDR',
        value: '3'
      }
    ],
  }),
  computed: {
    ...mapGetters({
      item: 'proformaInvoice/showItem',
      items: 'proformaInvoice/getItems'
    }),
    cells () {
      return [
        {
          header: this.$i18n.t('DateIssued'),
          field: 'date_issued'
        },
        {
          header: this.$i18n.t('CustomerName'),
          field: 'customer'
        },
        {
          header: this.$i18n.t('DateDue'),
          field: 'date_due'
        },
        {
          header: this.$i18n.t('NPWPCustomerNumber'),
          field: 'no_npwp_customer'
        },
        {
          header: this.$i18n.t('InvoiceDate'),
          field: 'invoice_date'
        },
        {
          header: this.$i18n.t('ProformaNumber'),
          field: 'invoice_number'
        },
        {
          header: this.$i18n.t('POContactNumber'),
          field: 'no_po_kontrak'
        },
        {
          header: this.$i18n.t('DODate'),
          field: 'do_date'
        },
        {
          header: this.$i18n.t('DONumber'),
          field: 'do_number'
        },
        {
          header: this.$i18n.t('TotalBillValue'),
          field: 'nilai_total_tagihan',
          format: (field) => {
            let value = field.nilai_total_tagihan;
            let val = (value/1).toFixed(0).replace('.', ',');
            let result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

            return `${this.currencies[field.matauang_total_tagihan-1].text} ${result}`;
          }
        },
        {
          header: this.$i18n.t('PaymentAccount'),
          field: 'rekening_pembayaran'
        },
        {
          header: this.$i18n.t('VAT'),
          field: 'pajak',
          format: (field) => {
            let value = field.pajak;
            let val = (value/1).toFixed(0).replace('.', ',');
            let result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

            return `${this.currencies[field.matauang_pajak-1].text} ${result}`;
          }
        },
        {
          header: this.$i18n.t('Description'),
          field: 'keterangan'
        },
        {
          header: this.$i18n.t('TotalInvoice'),
          field: 'invoice',
          format: (field) => {
            let value = field.invoice;
            let val = (value/1).toFixed(0).replace('.', ',');
            let result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

            return `${this.currencies[field.matauang_invoice-1].text} ${result}`;
          }
        },

      ]
    }
  },
  created () {
    this.id = this.$secure.decrypt(this.$route.params.id);
    this.$store.dispatch('proformaInvoice/detailItem', { id: this.id });
  }
}
</script>

<style scoped>

</style>
